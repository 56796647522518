import { gql } from "@apollo/client";

const GET_ME = gql`
  query getMe {
    getMe {
      userId
      email
    }
  }
`;

export default GET_ME;
