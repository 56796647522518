import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import Flex from "../Flex/Flex";
import { Container, Menu } from "./MenuOverlay.styled";
import { Link } from "../../components/Link/Link";
import Span from "../Span/Span";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useScreenClass } from "react-grid-system";

type MenuOverlayProps = {
  isActive: boolean;
  setIsActive: (value: boolean) => void;
};
const MenuOverlay = (props: MenuOverlayProps) => {
  const { isActive, setIsActive } = props;
  const screenClass = useScreenClass();

  const targetRef: any = React.createRef();

  React.useEffect(() => {
    setIsActive(false);
    enableBodyScroll(targetRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenClass, setIsActive]);

  React.useEffect(() => {
    if (targetRef) {
      if (isActive) {
        disableBodyScroll(targetRef.current);
      } else {
        enableBodyScroll(targetRef.current);
      }
    }
  }, [targetRef, isActive]);

  return (
    <Container isActive={isActive} ref={targetRef}>
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsActive(false);
        }}
      >
        <Menu isActive={isActive}>
          <div
            style={{ background: "rgba(99,110,114,0.8)", padding: "1rem 0" }}
          >
            <Flex flexDirection="column" flex={1}>
              <Flex
                flex={1}
                justifyContent="center"
                fontSize={32}
                mb={3}
                fontWeight={600}
              >
                Menu
              </Flex>
              <Flex
                flex={1}
                flexDirection="column"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Span fontWeight={200} fontSize={24}>
                  <Link to="/" onClick={() => setIsActive(false)}>
                    Home
                  </Link>
                </Span>
                <Span fontWeight={200} fontSize={24}>
                  <Link onClick={() => setIsActive(false)} to="/blog">
                    Blog
                  </Link>
                </Span>
                {/* <Span fontWeight={200} fontSize={24}><Link onClick={() => setIsActive(false)} to="/my-projects">My projects</Link></Span> */}
                {/* <Span fontWeight={200} fontSize={24}><Link onClick={() => setIsActive(false)} to="/contact">Contact</Link></Span> */}
              </Flex>
            </Flex>
          </div>
        </Menu>
      </OutsideClickHandler>
    </Container>
  );
};

export default MenuOverlay;
