import React from "react";
import { TextareaBase } from "./Textarea.styled";

type TextareaProps = {
  name: string;
  placeholder?: string;
  onChange?: (e: any) => void;
  value?: string;
};
const Textarea = (props: TextareaProps) => {
  const { name, placeholder, ...rest } = props;

  return <TextareaBase name={name} placeholder={placeholder} {...rest} />;
};

export default Textarea;
