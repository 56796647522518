import styled from "styled-components";

export const Container = styled.div<{ isActive: boolean }>`
// width:100%;
// height:100%;
${(props) =>
  props.isActive &&
  `
&::before{
  content: '';
  position: fixed;
  overflow:hidden;
  z-index:9998;
  height:100%;
  top:0;
  left:0;
  bottom:0;
  right:0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(8px);
  -moz-backdrop-filter: blur(8px);
  -o-backdrop-filter: blur(8px);
  -ms-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
`}
  }
`;

export const Menu = styled.div<{ isActive: boolean }>`
  transform: ${(props) => (props.isActive ? "scaleY(1)" : "scaleY(0)")};
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.8);
  height: ${(props) => (props.isActive ? "auto" : "0")};
  transition: transform 0.1s linear;
  transform-origin: bottom;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  opacity: 1;
  z-index: 9999;
`;
