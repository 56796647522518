import styled from "styled-components";
import {
  display,
  flexbox,
  fontSize,
  fontWeight,
  size,
  space,
} from "styled-system";
import { FlexProps } from "./Flex";

export const FlexBase = styled.div<FlexProps>`
  display: flex;
  ${flexbox}
  ${display}
  ${space}
  ${size}
  ${fontWeight}
  ${fontSize}
  ${(props) =>
    props.lineClamp &&
    `
  display: -webkit-box;
  -webkit-line-clamp: ${props.lineClamp};
  -webkit-box-orient: vertical;  
  overflow: hidden;
`}
`;
