import React from "react";
import { Editor, EditorState, RichUtils } from "draft-js";
import bold from "../../../assets/bold.png";
import italic from "../../../assets/italic.png";
import underline from "../../../assets/underline.png";
import { StyledEditor } from "./RichText.styled";

type RichTextProps = {
  onChange: (e: any) => void;
  value: EditorState;
};
const RichText = (props: RichTextProps) => {
  const { onChange, value } = props;

  const onBoldClick = (e: any) => {
    e.preventDefault();
    onChange(RichUtils.toggleInlineStyle(value, "BOLD"));
  };

  const onItalicClick = (e: any) => {
    e.preventDefault();
    onChange(RichUtils.toggleInlineStyle(value, "ITALIC"));
  };

  const onUnderlineClick = (e: any) => {
    e.preventDefault();
    onChange(RichUtils.toggleInlineStyle(value, "UNDERLINE"));
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ flex: 1 }}>
        <button
          style={{
            backgroundColor: "transparent",
            border: 0,
            outline: "none",
            cursor: "pointer",
          }}
          onClick={onBoldClick}
        >
          <img
            style={{ width: 14, height: 14 }}
            src={bold}
            title="bold"
            alt="bold"
          ></img>
        </button>
        <button
          style={{
            backgroundColor: "transparent",
            border: 0,
            outline: "none",
            cursor: "pointer",
          }}
          onClick={onItalicClick}
        >
          <img
            style={{ width: 14, height: 14 }}
            src={italic}
            title="italic"
            alt="italic"
          ></img>
        </button>
        <button
          style={{
            backgroundColor: "transparent",
            border: 0,
            outline: "none",
            cursor: "pointer",
          }}
          onClick={onUnderlineClick}
        >
          <img
            style={{ width: 14, height: 14 }}
            src={underline}
            title="underline"
            alt="underline"
          ></img>
        </button>
      </div>
      <div
        style={{
          flex: 1,
          backgroundColor: "#fff",
          border: "1px solid black",
          padding: "8px",
        }}
      >
        <StyledEditor>
          <Editor editorState={value} onChange={onChange} />
        </StyledEditor>
      </div>
    </div>
  );
};

export default RichText;
