import REFRESH_TOKEN from "../common/queries/REFRESH_TOKEN";

const getAccessToken = () => {
  return localStorage.getItem("ACCESS_TOKEN") || undefined;
};

const setAccessToken = (token: string) => {
  return localStorage.setItem("ACCESS_TOKEN", token);
};

const getRefreshToken = () => {
  return localStorage.getItem("REFRESH_TOKEN") || undefined;
};

const setRefreshToken = (refreshToken: string) => {
  return localStorage.setItem("REFRESH_TOKEN", refreshToken);
};

const renewToken = async (client: any) => {
  return client
    .mutate({
      mutation: REFRESH_TOKEN,
      variables: {
        refreshToken: await getRefreshToken(),
      },
    })
    .then((response: any) => {
      const { refreshToken, token } = response.data.refreshToken;
      setAccessToken(token);
      setRefreshToken(refreshToken);
      return token;
    });
};

const tokenUtil = {
  getAccessToken,
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
  renewToken,
};

export default tokenUtil;
