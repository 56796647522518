import React, { createContext } from "react";
import { useQuery } from "@apollo/client";
import GET_ME from "../../queries/GET_ME";
import tokenUtil from "../../../config/tokenUtil";

type State = {
  userId: string | undefined;
  email: string | undefined;
  signedIn: boolean | undefined;
  token?: string | undefined;
};

type SetState = React.Dispatch<React.SetStateAction<State>> | undefined;

type ContextProps = {
  state: State;
  setState: SetState;
  refetchGetMe: () => void;
};

export const AuthContext = createContext<ContextProps>({
  state: {
    userId: undefined,
    email: undefined,
    signedIn: undefined,
    token: undefined,
  },
  setState: undefined,
  refetchGetMe: () => null,
});

export const AuthContextProvider = (props: any) => {
  const [state, setState] = React.useState<State>({
    userId: undefined,
    email: undefined,
    signedIn: undefined,
    token: tokenUtil.getAccessToken(),
  });

  React.useEffect(() => {
    if (!state.token) {
      setState((prevState) => {
        return {
          ...prevState,
          signedIn: false,
        };
      });
    }
  }, [state.token]);

  const { data, refetch } = useQuery(GET_ME, {
    skip: !state.token,
    fetchPolicy: "no-cache",
    onError: () => {
      setState({
        userId: undefined,
        email: undefined,
        signedIn: false,
      });
    },
  });

  React.useEffect(() => {
    if (data && data.getMe) {
      setState((prevState) => {
        return {
          ...prevState,
          email: data.getMe.email,
          userId: data.getMe.userId,
          signedIn: true,
        };
      });
    }
  }, [data]);

  const value = {
    state,
    setState,
    refetchGetMe: refetch,
  };
  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};
