import React from "react";
import Flex from "../../common/components/Flex/Flex";
import { useHistory, useParams } from "react-router";
import { useQuery } from "@apollo/client";
import GET_POST from "../../common/queries/GET_POST";
import Span from "../../common/components/Span/Span";
import Button from "../../common/components/Button/Button";
import MessageDisplay from "../../common/components/MessageDisplay/MessageDisplay";

const ViewPost = () => {
  const history = useHistory();
  const { id }: any = useParams();
  const [title, setTitle] = React.useState("");
  const [content, setContent] = React.useState("");
  const { data, loading, error } = useQuery(GET_POST, {
    skip: !id,
    variables: {
      id: Number(id),
    },
    onCompleted: () => {
      setTitle(data.post.title);
      setContent(data.post.content);
    },
    fetchPolicy: "network-only",
  });
  return (
    <Flex flexDirection="column" px={4} flex={1}>
      {loading && <MessageDisplay message="Loading post..." />}
      {error && (
        <MessageDisplay message="There was an error while loading the post." />
      )}
      <Flex alignItems="center">
        {!loading && !error && (
          <Button primary onClick={() => history.push("/blog")}>
            Back to blog
          </Button>
        )}
      </Flex>
      <Flex>
        <Span fontSize={34}>{title}</Span>
      </Flex>
      <Flex mt={3}>
        <div
          style={{ marginTop: "16px" }}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </Flex>
    </Flex>
  );
};

export default ViewPost;
