import React from "react";
import Flex from "../../components/Flex/Flex";
import Span from "../../components/Span/Span";

const Footer = () => {
  return (
    <Flex mt={4} mb={2} justifyContent="center" flex={1}>
      <Span fontSize={20} textAlign="center" p={3} fontWeight={200}>
        Copyright &copy; Bogdan Bledea {new Date().getFullYear()}. Icons by{" "}
        <a
          style={{ textDecoration: "none", color: "#3498db" }}
          target="_blank"
          href="https://icons8.com/"
        >
          Icons8
        </a>
        .
      </Span>
    </Flex>
  );
};

export default Footer;
