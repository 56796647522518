import React, { useContext } from "react";
import { Col } from "react-grid-system";
import Flex from "../../../../common/components/Flex/Flex";
import { Link } from "../../../../common/components/Link/Link";
import edit from "../../../../assets/edit.png";
import deleteImg from "../../../../assets/delete.png";
import { AuthContext } from "../../../../common/components/AuthContext/AuthContext";
import moment from "moment";
import Span from "../../../../common/components/Span/Span";
import { useHistory } from "react-router";
import { Wrapper } from "./BlogPost.styled";

type Post = {
  title: string;
  createdAt: string;
  postid: number;
  content: string;
};

type BlogPostProps = {
  post: Post;
  handleDelete: (id: number) => void;
};

const BlogPost = (props: BlogPostProps) => {
  const { state } = useContext(AuthContext);
  const history = useHistory();
  const { post, handleDelete } = props;
  const convertedDate = moment(post.createdAt).format("D MMM YYYY");
  return (
    <Flex mt={4} flex={1}>
      <Wrapper>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <Flex flexDirection="column" flex={1}>
            <Flex flex={1}>
              <Flex flex={1} lineClamp={1}>
                <Link
                  to={`/blog/view/${post.postid}`}
                  fontSize={28}
                  fontWeight={600}
                >
                  {post.title}
                </Link>
              </Flex>
              {state.signedIn && (
                <Flex justifyContent="flex-end" alignItems="center" ml={2}>
                  <img
                    src={edit}
                    alt="edit"
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push(`/blog/edit/${post.postid}`)}
                    width={16}
                    height={16}
                  />
                  <img
                    src={deleteImg}
                    alt="delete"
                    style={{ cursor: "pointer", marginLeft: "10px" }}
                    onClick={() => handleDelete(post.postid)}
                    width={16}
                    height={16}
                  />
                </Flex>
              )}
            </Flex>
            <Flex>
              <Span opacity={0.5} fontSize={16}>
                Posted on {convertedDate}
              </Span>
            </Flex>
            <Flex>
              <div
                style={{ marginTop: "16px" }}
                dangerouslySetInnerHTML={{
                  __html: post.content,
                }}
              />
            </Flex>
          </Flex>
        </Col>
      </Wrapper>
    </Flex>
  );
};

export default BlogPost;
