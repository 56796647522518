import styled from "styled-components";

export const TextInputBase = styled.input`
  height: 36px;
  outline: none;
  font-family: "Source Sans Pro", sans-serif;
  width: 100%;
  max-width: 100%;
  padding: 0 10px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
`;
