import React from "react";
import Flex from "../Flex/Flex";
import Span from "../Span/Span";

type Props = {
  message: string;
};
const MessageDisplay = (props: Props) => {
  const { message } = props;

  return (
    <Flex flex={1} alignItems="center" justifyContent="center">
      <Span fontSize={36} opacity={0.3} my={4} textAlign="center">
        {message}
      </Span>
    </Flex>
  );
};

export default MessageDisplay;
