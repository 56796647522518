import React from "react";
import { SpaceProps } from "styled-system";
import { ButtonBase, LinkBase } from "./Button.styled";

export type ButtonProps = SpaceProps & {
  children: any;
  primary?: boolean;
  secondary?: boolean;
  onClick?: any;
  disabled?: boolean;
  buttonType?: "submit" | "button" | "reset";
  external?: boolean;
  target?: string;
  to?: string;
};
const Button = (props: ButtonProps) => {
  const { buttonType = "button", external, to } = props;
  if (external) {
    return (
      <LinkBase href={to} {...props}>
        {props.children}
      </LinkBase>
    );
  } else {
    return (
      <ButtonBase type={buttonType || ""} {...props}>
        {props.children}
      </ButtonBase>
    );
  }
};

export default Button;
