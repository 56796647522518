import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 10px 0;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
`;
