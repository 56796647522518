import React, { useContext } from "react";
import { Row } from "react-grid-system";
import Button from "../../common/components/Button/Button";
import Flex from "../../common/components/Flex/Flex";
import Span from "../../common/components/Span/Span";
import Modal from "react-modal";
import TextInput from "../../common/components/TextInput/TextInput";
import Textarea from "../../common/components/Textarea/Textarea";
import { useHistory } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
import GET_POSTS from "../../common/queries/GET_POSTS";
import DELETE_POST from "../../common/queries/DELETE_POST";
import toast from "react-hot-toast";
import { AuthContext } from "../../common/components/AuthContext/AuthContext";
import MessageDisplay from "../../common/components/MessageDisplay/MessageDisplay";
import BlogPost from "./components/BlogPost/BlogPost";

const Blog = () => {
  const { loading, data, error, refetch } = useQuery(GET_POSTS, {
    fetchPolicy: "network-only",
  });
  const [isOpen, setIsOpen] = React.useState(false);
  const history = useHistory();

  const [deletePost] = useMutation(DELETE_POST, {
    onCompleted: () => {
      toast.success("Deleted successfully!");
      refetch();
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const handleDelete = async (id: number) => {
    await deletePost({
      variables: {
        id: id,
      },
    });
  };

  const { state } = useContext(AuthContext);

  return (
    <>
      <Flex flexDirection="column" px={4} flex={1}>
        <Flex>
          <Flex flex={1} flexDirection="column">
            <Span fontSize={42} fontWeight={600}>
              Blog
            </Span>
          </Flex>
          {state.signedIn && (
            <Flex justifyContent="flex-end" alignItems="center">
              <Button primary onClick={() => history.push("/blog/new")}>
                Add new post
              </Button>
            </Flex>
          )}
        </Flex>
        <Flex>
          <Span fontWeight={200} fontSize={16}>
            I am writing here my personal experiences with regards to
            programming.
          </Span>
        </Flex>
        {loading && <MessageDisplay message="Loading posts..." />}
        {!loading && data && data.posts.length === 0 && (
          <MessageDisplay message="There are no blog posts. Yet." />
        )}
        {error && (
          <MessageDisplay message="There was an error while loading posts. please try again later." />
        )}
        {data &&
          data.posts.map((post: any) => {
            return (
              <Row key={post.postid}>
                <BlogPost post={post} handleDelete={handleDelete} />
              </Row>
            );
          })}
      </Flex>
      <Modal
        isOpen={isOpen}
        contentLabel="Minimal Modal Example"
        style={{
          content: {
            width: "520px",
            height: "300px",
            left: "50%",
            top: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <Flex flexDirection="column">
          <Flex flex={1} justifyContent="flex-end">
            <button onClick={() => setIsOpen(false)}>x</button>
          </Flex>
          <Flex mt={2} flex={1} flexDirection="column">
            <Span>Title</Span>
            <TextInput name="title" placeholder="Type here the title..." />
            <Span>Content</Span>
            <Textarea name="content" placeholder="Type here the title..." />
            <Button primary>Post</Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default Blog;
