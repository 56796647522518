import React from "react";
import {
  DisplayProps,
  FlexboxProps,
  FontSizeProps,
  FontWeightProps,
  SizeProps,
  SpaceProps,
} from "styled-system";
import { FlexBase } from "./Flex.styled";

export type FlexProps = FlexboxProps &
  SpaceProps &
  DisplayProps &
  SizeProps &
  FontWeightProps &
  FontSizeProps & {
    children?: any;
    lineClamp?: number;
  };

const Flex = (props: FlexProps) => {
  return <FlexBase {...props}>{props.children}</FlexBase>;
};

export default Flex;
