import React from "react";
import {
  FontSizeProps,
  FontWeightProps,
  LineHeightProps,
  OpacityProps,
  SpaceProps,
  TextAlignProps,
} from "styled-system";
import { SpanBase } from "./Span.styled";

export type SpanProps = FontWeightProps &
  TextAlignProps &
  FontSizeProps &
  SpaceProps &
  LineHeightProps &
  OpacityProps & {
    children?: any;
  };
const Span = (props: SpanProps) => {
  return <SpanBase {...props}>{props.children}</SpanBase>;
};

export default Span;
