import { gql } from "@apollo/client";

const DELETE_POST = gql`
  mutation ($id: Float!) {
    deletePost(id: $id) {
      success
    }
  }
`;

export default DELETE_POST;
