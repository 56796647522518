import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import { ButtonProps } from "./Button";

export const ButtonBase = styled.button<ButtonProps>`
  ${space}
  border:0;
  border-radius: 5px;
  font-weight: 600;
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
  padding: 5px 15px;
  cursor: pointer;
  height: 32px;
  outline: none;
  ${(props) => props.secondary && `background-color: #636e72;`}
  ${(props) => props.primary && `background-color: #2c3e50;`}
  ${(props) => props.disabled && "opacity: 0.5;"}
  &:hover {
    opacity: 0.5;
  }
`;

export const LinkBase = styled.a<SpaceProps>`
  ${space}
  font-size:0.875rem;
  text-decoration: none;
  border: 0;
  border-radius: 5px;
  font-weight: 600;
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
  padding: 5px 15px;
  cursor: pointer;
  outline: none;
  background-color: #636e72;
  &:hover {
    opacity: 0.5;
  }
`;
