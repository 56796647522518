import { gql } from "@apollo/client";

const GET_POST = gql`
  query getPost($id: Float!) {
    post(id: $id) {
      postid
      title
      content
      createdAt
    }
  }
`;

export default GET_POST;
