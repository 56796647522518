import React from "react";
import { setConfiguration } from "react-grid-system";
import { AuthContextProvider } from "./common/components/AuthContext/AuthContext";
import { ThemeProvider } from "styled-components";
import { Toaster } from "react-hot-toast";
import Router from "./Router/Router";
import theme from "./config/theme";
import GlobalStyle from "./config/global-style";
import Flex from "./common/components/Flex/Flex";

setConfiguration({ maxScreenClass: "xl" });

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <Flex flexDirection="column" flex={1}>
          <Toaster />
          <GlobalStyle />
          <Router />
        </Flex>
      </AuthContextProvider>
    </ThemeProvider>
  );
};

export default App;
