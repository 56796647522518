import React from "react";
import { TextInputBase } from "./TextInput.styled";

type TextInputProps = {
  name: string;
  placeholder?: string;
  onChange?: (e: any) => void;
  value?: string;
  type?: string;
};
const TextInput = (props: TextInputProps) => {
  const { name, placeholder, ...rest } = props;
  return (
    <TextInputBase
      type="text"
      name={name}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default TextInput;
