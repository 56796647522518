import { useMutation } from "@apollo/client";
import React, { useContext } from "react";
import { Col, Row } from "react-grid-system";
import Button from "../../common/components/Button/Button";
import Flex from "../../common/components/Flex/Flex";
import Span from "../../common/components/Span/Span";
import TextInput from "../../common/components/TextInput/TextInput";
import LOGIN from "../../common/queries/LOGIN";
import toast from "react-hot-toast";
import tokenUtil from "../../config/tokenUtil";
import { Redirect, useHistory } from "react-router";
import { AuthContext } from "../../common/components/AuthContext/AuthContext";

const Login = () => {
  const history = useHistory();
  const [email, setEmail] = React.useState("");
  const [pass, setPass] = React.useState("");

  const { state, refetchGetMe } = useContext(AuthContext);

  const [login] = useMutation(LOGIN, {
    onCompleted: async (data) => {
      toast.success("Login successfully!");
      tokenUtil.setAccessToken(data.login.token);
      tokenUtil.setRefreshToken(data.login.refreshToken);
      await refetchGetMe();
      history.push("/");
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const handleLogin = async () => {
    await login({
      variables: {
        email: email,
        password: pass,
      },
    });
  };

  if (state.signedIn) {
    return <Redirect to="/" />;
  }
  return (
    <Flex flexDirection="column" px={4} flex={1}>
      <Row>
        <Col lg={3} xl={3} md={12} sm={12} xs={12}>
          <Span>Email</Span>
          <TextInput
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={3} xl={3} md={12} sm={12} xs={12}>
          <Span>Password</Span>
          <TextInput
            value={pass}
            onChange={(e) => setPass(e.target.value)}
            name="password"
            type="password"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            buttonType="submit"
            mt={3}
            disabled={!email || !pass}
            onClick={handleLogin}
            primary
          >
            Login
          </Button>
        </Col>
      </Row>
    </Flex>
  );
};

export default Login;
