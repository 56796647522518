import { gql } from "@apollo/client";

const CREATE_POST = gql`
  mutation ($data: PostInput!) {
    createPost(data: $data) {
      postid
      title
      content
    }
  }
`;

export default CREATE_POST;
