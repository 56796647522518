import styled from "styled-components";

export const TextareaBase = styled.textarea`
  font-family: "Source Sans Pro", sans-serif;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 300px;
  resize: none;
  padding: 5px 10px;
`;
