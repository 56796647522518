import React from "react";
import { useScreenClass, Visible } from "react-grid-system";
import Flex from "../../components/Flex/Flex";
import { Link } from "../../components/Link/Link";
import Span from "../../components/Span/Span";
import menu from "../../../assets/menu.png";
import MenuOverlay from "../../components/MenuOverlay/MenuOverlay";
import { Logo } from "./Header.styled";

const Header = () => {
  const [isActive, setIsActive] = React.useState(false);
  const screenClass = useScreenClass();

  // If user resizes the screen, close the dropdown
  React.useEffect(() => {
    setIsActive(false);
  }, [screenClass]);

  return (
    <Flex flex={1}>
      <Flex p={4} flex={1}>
        <Flex flexDirection="column">
          <Logo to="/">
            <Span fontWeight={200} fontSize={34}>
              Bogdan
            </Span>
            <Span fontWeight={400} fontSize={34}>
              Bledea
            </Span>
          </Logo>
          <Span fontWeight={200}>v1.0.1</Span>
        </Flex>
        <Visible md lg xl>
          <Flex flex={1} justifyContent="flex-end" alignItems="center">
            <Span fontWeight={200} ml={3} fontSize={24}>
              <Link to="/">Home</Link>
            </Span>
            <Span fontWeight={200} ml={3} fontSize={24}>
              <Link to="/blog">Blog</Link>
            </Span>
            {/* <Span fontWeight={200} ml={3} fontSize={24}><Link to="/my-projects">My projects</Link></Span> */}
            {/* <Span fontWeight={200} ml={3} fontSize={24}><Link to="/contact">Contact</Link></Span> */}
          </Flex>
        </Visible>
        <Visible sm xs>
          <Flex flex={1} justifyContent="flex-end" alignItems="center">
            <img
              src={menu}
              alt="menu"
              onClick={() => setIsActive(!isActive)}
              height={24}
              width={24}
            />
          </Flex>
        </Visible>
      </Flex>
      <MenuOverlay isActive={isActive} setIsActive={setIsActive} />
    </Flex>
  );
};

export default Header;
