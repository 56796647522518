import { gql } from "@apollo/client";

const EDIT_POST = gql`
  mutation ($id: Float!, $data: PostInput!) {
    editPost(id: $id, data: $data) {
      success
    }
  }
`;

export default EDIT_POST;
