import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  from,
  createHttpLink,
  fromPromise,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import tokenUtil from "./tokenUtil";

const httpLink = createHttpLink({
  uri: "/api",
  credentials: "same-origin",
});

let client: any;

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err && err.extensions && err.extensions.code) {
          // In case we get UNAUTHENTICATED, the user is not logged in. We will redirect to /login
          case "UNAUTHENTICATED":
            window.location.pathname = "/login";
            break;
          // In case user gets FORBIDDEN, it means that the token is most probably expired.
          // We will try to renew it
          case "FORBIDDEN":
            return fromPromise(
              tokenUtil.renewToken(client).catch((error: any) => {
                // if the renew procedure is not successful,
                // we will redirect user to the login page
                window.location.pathname = "/login";
                localStorage.clear();
                return;
              })
            )
              .filter((value) => Boolean(value))
              .flatMap((accessToken) => {
                const oldHeaders = operation.getContext().headers;
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${accessToken}`,
                  },
                });
                return forward(operation);
              });
        }
      }
    }
  }
);

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${tokenUtil.getAccessToken()}` || null,
      "refresh-token": tokenUtil.getRefreshToken() || null,
    },
  }));

  return forward(operation);
});

client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authMiddleware, errorLink, httpLink]),
});

export default client;
