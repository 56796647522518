import React from "react";
import me from "../../assets/me.jpg";
import code from "../../assets/icons8-code-file-100.png";
import university from "../../assets/icons8-graduation-cap-100.png";
import toolbox from "../../assets/icons8-administrative-tools-100.png";
import communication from "../../assets/icons8-edit-chat-history-100.png";
import responsive from "../../assets/icons8-monitor-100.png";
import Button from "../../common/components/Button/Button";
import Flex from "../../common/components/Flex/Flex";
import { ProfileImage } from "../../common/components/ProfileImage/ProfileImage";
import Span from "../../common/components/Span/Span";
import { Row, Col, useScreenClass } from "react-grid-system";

const Home = () => {
  const screenClass = useScreenClass();

  return (
    <Flex flexDirection="column" px={4}>
      <Row>
        <Col xl={6} lg={6} md={6}>
          <Flex
            flex={1}
            justifyContent={
              screenClass === "lg" ||
              screenClass === "md" ||
              screenClass === "xl"
                ? "flex-end"
                : "center"
            }
          >
            <ProfileImage alt="Profile pic" src={me} />
          </Flex>
        </Col>
        <Col xl={6} lg={6} md={6}>
          <Flex
            flex={1}
            justifyContent="flex-start"
            flexDirection="column"
            ml={screenClass === "lg" || screenClass === "xl" ? 3 : 0}
          >
            <Span
              fontWeight={200}
              fontSize={30}
              textAlign={
                screenClass === "sm" || screenClass === "xs" ? "center" : "left"
              }
            >
              Full stack developer
            </Span>
            <Span
              fontWeight={600}
              fontSize={40}
              mt={2}
              textAlign={
                screenClass === "sm" || screenClass === "xs" ? "center" : "left"
              }
            >
              Bogdan Bledea
            </Span>
            <Span
              fontWeight={300}
              fontSize={20}
              mt={2}
              textAlign={
                screenClass === "md" ||
                screenClass === "sm" ||
                screenClass === "xs"
                  ? "center"
                  : "left"
              }
            >
              I am a passionate developer, with 3 years of working with React,
              with experience in cloud computing, to build amazing
              infrastructure for host scalable apps.
            </Span>
            <Flex mt={3}>
              <Button
                external
                to="https://github.com/bogdanbledea"
                target="_blank"
                secondary
              >
                @Github
              </Button>
              <Button
                external
                to="https://www.linkedin.com/in/bogdanbledea/"
                target="_blank"
                secondary
                ml={2}
              >
                @LinkedIn
              </Button>
            </Flex>
          </Flex>
        </Col>
      </Row>
      <Flex flexDirection="column" mt={4}>
        <Row>
          <Col>
            <Span fontSize={34} fontWeight={200}>
              About me
            </Span>
          </Col>
        </Row>
      </Flex>
      <Row>
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Flex>
            <Flex>
              <img alt="code" src={code} height={64} width={64} />
            </Flex>
            <Flex>
              <Span fontWeight={200} pl={3}>
                3 years of working with React. I have experience with
                integrating REST and GraphQL APIs, good skills working with
                Version Control software(Git & Github), experience working with
                Typescript.
              </Span>
            </Flex>
          </Flex>
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Flex
            mt={
              screenClass === "md" ||
              screenClass === "sm" ||
              screenClass === "xs"
                ? 3
                : 0
            }
          >
            <Flex>
              <img
                alt="communication"
                src={communication}
                height={64}
                width={64}
              />
            </Flex>
            <Flex>
              <Span fontWeight={200} pl={3}>
                Working in an Agile team, following Agile practices and
                methodologies (Kanban, Scrum). I am a dedicated team player,
                willing to ask for help and provide support whenever needed.
              </Span>
            </Flex>
          </Flex>
        </Col>
      </Row>
      <Flex flexDirection="column" mt={3}>
        <Row>
          <Col xl={6} lg={6} md={12} sm={12} xs={12}>
            <Flex>
              <Flex>
                <img alt="toolbox" src={toolbox} height={64} width={64} />
              </Flex>
              <Flex>
                <Span fontWeight={200} pl={3}>
                  Understanding the web development process(Design, Development,
                  Continuous Integration and Delivery). Experience working with
                  Docker & Kubernetes (Pods, Deployments, Services).
                </Span>
              </Flex>
            </Flex>
          </Col>
          <Col xl={6} lg={6} md={12} sm={12} xs={12}>
            <Flex
              mt={
                screenClass === "md" ||
                screenClass === "sm" ||
                screenClass === "xs"
                  ? 3
                  : 0
              }
            >
              <Flex>
                <img alt="responsive" src={responsive} height={64} width={64} />
              </Flex>
              <Flex>
                <Span fontWeight={200} pl={3}>
                  Creating responsive, mobile-first UI websites and
                  applications, so it fits on every screen.
                </Span>
              </Flex>
            </Flex>
          </Col>
        </Row>
        <Flex flexDirection="column" mt={4}>
          <Row>
            <Col>
              <Span fontSize={34} fontWeight={200}>
                Education
              </Span>
            </Col>
          </Row>
          <Row>
            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <Flex>
                <Flex>
                  <img
                    alt="university"
                    src={university}
                    height={64}
                    width={64}
                  />
                </Flex>
                <Flex flexDirection="column" pl={3}>
                  <Span fontWeight={600} fontSize={20}>
                    Master’s degree
                  </Span>
                  <Span fontWeight={200} fontSize={20}>
                    Polytechnic University of Timisoara (2019-2021)
                  </Span>
                  <Span fontWeight={200}>
                    Technologies, Systems and Applications for eActivities
                  </Span>
                </Flex>
              </Flex>
            </Col>
          </Row>
          <Row>
            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <Flex mt={3}>
                <Flex>
                  <img
                    alt="university"
                    src={university}
                    height={64}
                    width={64}
                  />
                </Flex>
                <Flex flexDirection="column" pl={3}>
                  <Span fontWeight={600} fontSize={20}>
                    Bachelor’s degree
                  </Span>
                  <Span fontWeight={200} fontSize={20}>
                    Polytechnic University of Timisoara (2015-2019)
                  </Span>
                  <Span fontWeight={200}>
                    Faculty of Electronics, Telecommunications and Information
                    Technology
                  </Span>
                </Flex>
              </Flex>
            </Col>
          </Row>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Home;
