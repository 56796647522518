import styled from "styled-components";

export const ProfileImage = styled.img`
  border: 15px solid #ffffff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 190px;
  height: 275px;
  width: 275px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  user-select: none;
  user-drag: none;
`;
