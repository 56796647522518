import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #ECF0F1;
    font-family: 'Source Sans Pro', sans-serif;
    height:100%;
    max-width:100%;
  }
  html{
    height:100%;
    max-width:100%;
  }
  #root{
    height:100%;
    display:flex;
    flex-direction:column;
  }
  p{
    margin:0;
  }
`;

export default GlobalStyle;
