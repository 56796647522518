import { gql } from "@apollo/client";

const GET_POSTS = gql`
  query getPosts {
    posts {
      postid
      title
      content
      createdAt
    }
  }
`;

export default GET_POSTS;
