import React from "react";
import { useHistory, useParams } from "react-router";
import Button from "../../common/components/Button/Button";
import Flex from "../../common/components/Flex/Flex";
import Span from "../../common/components/Span/Span";
import TextInput from "../../common/components/TextInput/TextInput";
import { useMutation, useQuery } from "@apollo/client";
import CREATE_POST from "../../common/queries/CREATE_POST";
import EDIT_POST from "../../common/queries/EDIT_POST";
import GET_POST from "../../common/queries/GET_POST";
import toast from "react-hot-toast";
import RichText from "../../common/components/RichText/RichText";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

const NewBlogPost = () => {
  const { id }: any = useParams();
  const history = useHistory();
  const [title, setTitle] = React.useState("");
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );

  let html = stateToHTML(editorState.getCurrentContent());

  const [createPost] = useMutation(CREATE_POST, {
    onCompleted: () => {
      toast.success("Post created successfully");
      history.push("/blog");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const [editPost] = useMutation(EDIT_POST, {
    onCompleted: () => {
      toast.success("Post edited successfully!");
      history.push("/blog");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const { data } = useQuery(GET_POST, {
    skip: !id,
    variables: {
      id: Number(id),
    },
    onCompleted: () => {
      setTitle(data.post.title);
      const html = convertFromHTML(data.post.content);
      const states = ContentState.createFromBlockArray(
        html.contentBlocks,
        html.entityMap
      );
      const final = EditorState.createWithContent(states);
      setEditorState(final);
    },
    fetchPolicy: "network-only",
  });

  const handleSubmit = async () => {
    if (id) {
      await editPost({
        variables: {
          id: Number(id),
          data: {
            title,
            content: html,
          },
        },
      });
    } else {
      await createPost({
        variables: {
          data: {
            title,
            content: html,
          },
        },
      });
    }
  };

  return (
    <Flex flexDirection="column" px={4} flex={1}>
      <Flex alignItems="center">
        <Button primary onClick={() => history.push("/blog")}>
          Back to blog
        </Button>
      </Flex>
      <Flex>
        <Span fontSize={42} fontWeight={600}>
          New Blog Post
        </Span>
      </Flex>
      <Flex flexDirection="column" mt={4}>
        <Span>Title</Span>
        <TextInput
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          name="title"
        />
      </Flex>
      <Flex flexDirection="column" mt={3}>
        <RichText value={editorState} onChange={setEditorState} />
      </Flex>
      <Flex mt={3}>
        <Button
          disabled={!title || !editorState}
          onClick={handleSubmit}
          primary
        >
          Post
        </Button>
      </Flex>
    </Flex>
  );
};

export default NewBlogPost;
