import React, { useContext } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  RouteProps,
  Redirect,
} from "react-router-dom";
import { AuthContext } from "../common/components/AuthContext/AuthContext";
import Flex from "../common/components/Flex/Flex";
import Footer from "../common/layout/Footer/Footer";
import Header from "../common/layout/Header/Header";
import Blog from "../pages/Blog/Blog";
import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import NewBlogPost from "../pages/NewBlogPost/NewBlogPost";
import ViewPost from "../pages/ViewPost/ViewPost";

const Router = () => {
  const auth = useContext(AuthContext);
  const { state } = auth;

  const RestrictedRoute = (props: RouteProps) => {
    if (state.signedIn) {
      return <Route {...props} />;
    } else {
      return <Redirect to="/" />;
    }
  };

  return (
    <Flex flexDirection="column" flex={1}>
      <BrowserRouter>
        <Flex>
          <Header />
        </Flex>
        <Flex flex={1}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/blog" exact>
              <Blog />
            </Route>
            <RestrictedRoute path="/blog/new">
              <NewBlogPost />
            </RestrictedRoute>
            <RestrictedRoute path="/blog/edit/:id" exact>
              <NewBlogPost />
            </RestrictedRoute>
            <Route path="/blog/view/:id" exact>
              <ViewPost />
            </Route>
            <Route path="/login" exact>
              <Login />
            </Route>
          </Switch>
        </Flex>
        <Flex>
          <Footer />
        </Flex>
      </BrowserRouter>
    </Flex>
  );
};

export default Router;
